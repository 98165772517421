export const exp3StimuliConstObject = {
  STIMULUS_0: 'STIMULUS_0',
  STIMULUS_1: 'STIMULUS_1',
  STIMULUS_2: 'STIMULUS_2',
  STIMULUS_3: 'STIMULUS_3',
  STIMULUS_4: 'STIMULUS_4',
  STIMULUS_5: 'STIMULUS_5',
  STIMULUS_6: 'STIMULUS_6',
  STIMULUS_7: 'STIMULUS_7',
}
export const exp3StimuliArray = Object.values(exp3StimuliConstObject)
